.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  top: 6px;
  font-size: 10px;
}

.red {
  color: rgba(240, 29, 64, 0.76);
}

.green {
  color: rgba(41, 133, 56, 0.671)
}


.example {
  margin: 12px;
}

Force update ant style
.ant-input {
  padding: 16px 12px 4px 11px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.ant-select .ant-select-selector {
  /* padding: 16px 10px 4px 11px; */
  color: rgba(34, 37, 35, 0.671);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* padding: 16px 10px 4px 11px; */
  height: 34px;
  /* color: blue; */
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
} */
  